// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import type { SigninType } from "bus/auth/models";
import type { FormSubmitParams } from "models/Forms";
import { HttpStatusCode, HttpServerErrorCode } from "models/Http";
import {
  GTMTypesEnum,
  ParametersEnum,
  VisitorTypeEnum,
  AuthorizationTypeEnum,
  ErrorTypeEnum,
} from "client/utils/gtm/gtm.types";

// Hooks
import { useAuthLoginMutation } from "bus/auth/mutations/authLoginMutation";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useLeadSignupVerifiedCreate } from "client/core/lead-signup/hooks/use-lead-signup-verified-create";

// Utils
import { book } from "init/book";
import { GET_ENUMS, GET_PARAMS, LEAD_LOGIN_KEY } from "utils/constants";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { fillServerErrorsToForm } from "utils/forms";
import { notificationService } from "utils/notifications";
import { gtmService } from "client/utils/gtm";

type OnLogingCbType = (values: FormSubmitParams<SigninType>) => void;
type UseLogin = {
  loadingLogin: boolean;
  onLogin: OnLogingCbType;
};

export const useLogin = (): UseLogin => {
  const router = useRouter();
  const { onCreateLeadSignupVerified, loadingVerifiedLeadSignupCreate } =
    useLeadSignupVerifiedCreate();
  const [, storeInLS] = useLocalStorage(LEAD_LOGIN_KEY, "");
  const { loadingAuthLogin, onLoginAuth } = useAuthLoginMutation();

  const onLogin = useCallback<OnLogingCbType>((data) => {
    void (async () => {
      try {
        const [authLoginErr, authLoginData] = await onLoginAuth(data.values);

        if (authLoginErr) {
          const isError400 = authLoginErr.status === HttpStatusCode.badRequest;
          const isError403 = authLoginErr.status === HttpStatusCode.forbidden;
          const isError422 = authLoginErr.status === HttpStatusCode.unprocessableEntity;
          const isError500 = authLoginErr.status >= HttpStatusCode.server;
          if (isError403) {
            gtmService.event(GTMTypesEnum.authorization, {
              [ParametersEnum.visitor_type]: VisitorTypeEnum.unknown_user,
              [ParametersEnum.authorization_type]: AuthorizationTypeEnum.authorization_type_error,
              [ParametersEnum.error_type]: ErrorTypeEnum.forbidden,
            });
            return await router.replace({
              pathname: book.signin,
              query: { ...router.query, [GET_PARAMS.popup]: GET_ENUMS.popup.signinError },
            });
          }
          if (isError422) {
            if (authLoginErr.errorCode === HttpServerErrorCode.validation) {
              gtmService.event(GTMTypesEnum.authorization, {
                [ParametersEnum.visitor_type]: VisitorTypeEnum.unknown_user,
                [ParametersEnum.authorization_type]: AuthorizationTypeEnum.authorization_type_error,
                [ParametersEnum.error_type]: ErrorTypeEnum.validation,
              });
              const errs = authLoginErr.errors;
              if (errs instanceof Object && errs) {
                return fillServerErrorsToForm(errs, data.acts?.setError);
              }
              throw new Error("Flow login validation limit has error");
            }
            if (authLoginErr.errorCode === HttpServerErrorCode.leadVerified) {
              gtmService.event(GTMTypesEnum.authorization, {
                [ParametersEnum.visitor_type]: VisitorTypeEnum.unknown_user,
                [ParametersEnum.authorization_type]: AuthorizationTypeEnum.authorization_type_error,
                [ParametersEnum.error_type]: ErrorTypeEnum.leadVerified,
              });
              return onCreateLeadSignupVerified(data.values);
            }
            if (authLoginErr.errorCode === HttpServerErrorCode.leadRegistered) {
              gtmService.event(GTMTypesEnum.authorization, {
                [ParametersEnum.visitor_type]: VisitorTypeEnum.unknown_user,
                [ParametersEnum.authorization_type]: AuthorizationTypeEnum.authorization_type_error,
                [ParametersEnum.error_type]: ErrorTypeEnum.leadRegistered,
              });
              storeInLS(data.values.login);

              return await router.replace({
                pathname: router.pathname,
                query: {
                  ...router.query,
                  [GET_PARAMS.popup]: GET_ENUMS.popup.leadRegistered,
                },
              });
            }
            throw new Error("Flow create login has error");
          }

          gtmService.event(GTMTypesEnum.authorization, {
            [ParametersEnum.visitor_type]: VisitorTypeEnum.unknown_user,
            [ParametersEnum.authorization_type]: AuthorizationTypeEnum.authorization_type_error,
            [ParametersEnum.error_type]: ErrorTypeEnum.unknown,
          });
          if (isError400 || isError500) {
            return notificationService.showError(getHttpErrorMessageWithTranslations());
          }
          throw new Error("Flow create login has error");
        }

        authLoginData?.profile?.customerId &&
          gtmService?.event(GTMTypesEnum.authorization, {
            [ParametersEnum.user_id]: authLoginData.profile.customerId,
            [ParametersEnum.visitor_type]: VisitorTypeEnum.logged_user,
            [ParametersEnum.authorization_type]: AuthorizationTypeEnum.authorization_type_success,
          });

        await router.replace({
          pathname: book.dashboard,
        });

        data?.acts?.reset?.();
      } catch (errors) {
        gtmService.event(GTMTypesEnum.authorization, {
          [ParametersEnum.visitor_type]: VisitorTypeEnum.unknown_user,
          [ParametersEnum.authorization_type]: AuthorizationTypeEnum.authorization_type_error,
          [ParametersEnum.error_type]: ErrorTypeEnum.unknown,
        });
        notificationService.showError(getHttpErrorMessageWithTranslations());
      }
    })();
  }, []);

  return {
    loadingLogin: loadingVerifiedLeadSignupCreate || loadingAuthLogin,
    onLogin,
  };
};
