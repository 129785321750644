// Components
import { Leads } from "../Leads";

// Utils
import { introLead } from "data/intro";
import { ScrollEnum } from "data/common";
import { useTranslation } from "client/utils/i18n/client";

export const SigninIntroLead = () => {
  const { t } = useTranslation();

  return (
    <Leads
      title={t(introLead.title)}
      buttonText={t(introLead.btn)}
      scrollName={ScrollEnum.toIntroInTouch}
      type="primary"
    />
  );
};
