// Core
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import cx from "classnames";

// Components
import { Form } from "antd";
import { Link } from "components/common/Link";
import { WrappedFormInput } from "components/common/WrappedFormInput";
import { WrappedFormPassword } from "components/common/WrappedFormPassword";
import { SigninFormInfo } from "components/common/SigninFormInfo";
import { FormTitle } from "components/ui/FormTitle";
import { Button } from "components/ui/Button";
import { InputForm } from "components/ui/FormFields/InputForm";
import { FormBlock } from "components/ui/FormFields/FormBlock";

// Definitions
import { FormSigninEnum, FormSubmitFn } from "models/Forms";
import type { SigninType } from "bus/auth/models";

// Utils
import { GET_ENUMS, GET_PARAMS } from "utils/constants";
import { getFieldsMetaValidation } from "utils/forms";
import { signinForm, inputConfig } from "./config";
import { book } from "init/book";
import { routerPush } from "utils/router";
import { useTranslation } from "client/utils/i18n/client";

import st from "./styles.module.css";

type SigninFormType = {
  loading?: boolean;
  size?: "middle" | "large";
  onSubmit?: FormSubmitFn<SigninType>;
  testId?: string;
  signinInfoFormTestId?: string;
};

export const SigninForm = (props: SigninFormType) => {
  const { loading, onSubmit, testId, size = "large", signinInfoFormTestId = "signin-info" } = props;
  const { t } = useTranslation();

  const formProps = useForm<SigninType>({
    defaultValues: { ...signinForm.shape },
    resolver: zodResolver(signinForm.schema(t)),
    mode: "all",
    reValidateMode: "onSubmit",
  });

  const { control, handleSubmit, formState, setError, reset } = formProps;

  const handleRegister = () => {
    void (async () => {
      await routerPush(book.signup);
    })();
  };
  const onSubmitForm = () => {
    void (async () => {
      await handleSubmit((values) => {
        onSubmit?.({
          values,
          acts: {
            setError,
            reset,
          },
        });
      })();
    })();
  };
  const fieldsMeta = getFieldsMetaValidation(FormSigninEnum, formState);

  const formStyles = cx(st["signin-form"], {
    [st[`signin-form-size-${size}`]]: Boolean(size),
  });

  return (
    <Form onFinish={onSubmitForm} data-test-id={testId} className={formStyles}>
      <>
        <FormTitle>{t("signin:formTitle")}</FormTitle>
        <FormBlock>
          <InputForm
            id={FormSigninEnum.login}
            name={FormSigninEnum.login}
            validateStatus={fieldsMeta.login.status}
            {...fieldsMeta.login.helpText}
          >
            <WrappedFormInput
              name={FormSigninEnum.login}
              control={control}
              placeholder={t(inputConfig.login.placeholder)}
              autoFocus
            />
          </InputForm>
        </FormBlock>

        <FormBlock>
          <InputForm
            id={FormSigninEnum.password}
            name={FormSigninEnum.password}
            validateStatus={fieldsMeta.password.status}
            {...fieldsMeta.password.helpText}
          >
            <WrappedFormPassword
              name={FormSigninEnum.password}
              control={control}
              placeholder={t(inputConfig.password.placeholder)}
            />
          </InputForm>
        </FormBlock>

        <FormBlock alignmentHorizontal="right">
          <Link
            testId="signin-forgot-pwd"
            href={{
              pathname: book.signin,
              query: { [GET_PARAMS.popup]: GET_ENUMS.popup.resetPasswordEmail },
            }}
            className={st["forgot-password-link"]}
          >
            {t("signin:forgotPass")}
          </Link>
        </FormBlock>
        <FormBlock>
          <div className={st.actions}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
              loading={loading}
              testId="signin-btn-access"
            >
              {t("buttons:signIn")}
            </Button>
            <Button
              href={book.signup}
              testId="signin-btn-reject"
              type="link"
              size="large"
              block
              mode="custom"
              onClick={handleRegister}
            >
              {t("buttons:registration")}
            </Button>
          </div>
        </FormBlock>
        <SigninFormInfo testId={signinInfoFormTestId} />
      </>
    </Form>
  );
};
