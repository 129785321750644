// Core
import { useCallback } from "react";

// Definitions
import type { HttpErrorType } from "models/Http";
import type { LeadHash, LeadParamsType } from "client/core/lead-signup/models";
import type { SigninType } from "bus/auth/models";
import type { BaseQueryKey } from "models/Base";

// Domains
import { leadSignupKeys } from "client/core/lead-signup/store";

// Utils
import { leadSignupService } from "client/core/lead-signup/service";
import { useMutationAsync } from "utils/react-query/hooks/use-mutation-async";

type UseCallbackCreateVerifiedLeadSignupType = (
  data: SigninType,
) => Promise<[HttpErrorType | null, LeadHash | null]>;

type UseLeadSignupVerifiedCreateMutationType = {
  loadingVerifiedLeadSignupCreate: boolean;
  onCreateLeadSignupVerified: UseCallbackCreateVerifiedLeadSignupType;
};

export const useLeadSignupVerifiedCreateMutation = (): UseLeadSignupVerifiedCreateMutationType => {
  const queryKey = [leadSignupKeys.verifiedLead];

  const createLeadSignupVerifiedProps = useMutationAsync<
    LeadHash,
    SigninType,
    SigninType,
    BaseQueryKey,
    LeadParamsType
  >({
    key: queryKey,
    name: "createVerifiedLeadSignupMutation",
    fetcher: leadSignupService.createVerifiedLead,
  });

  const onCreateLeadSignupVerified = useCallback<UseCallbackCreateVerifiedLeadSignupType>(
    async (data) => {
      try {
        const createLeadVerifiedResult = await createLeadSignupVerifiedProps.mutateAsync(data);
        return [null, createLeadVerifiedResult];
      } catch (err) {
        return [err as HttpErrorType, null];
      }
    },
    [],
  );

  return {
    loadingVerifiedLeadSignupCreate: createLeadSignupVerifiedProps.isLoading,
    onCreateLeadSignupVerified,
  };
};
