// Core

// Components
import { Link } from "components/common/Link";
import { Text } from "components/ui/Typography/Text";

// Hooks
import { useUserCountry } from "bus/user/hooks/useUserCountry";
import { useTranslation } from "client/utils/i18n/client";

// Utils
import { composeGmailLink } from "utils/helpers";
import { emailsBook } from "init/book";
import { getManagerContactPhoneNumber } from "utils/constants";
import { getFormatPhoneNumber } from "utils/getFormatPhoneNumber";

import st from "./styles.module.css";

export const SigninFormInfo = ({ testId }: { testId: string }) => {
  const { t } = useTranslation();
  const { userCountry } = useUserCountry();

  const needHelpText = t("common:helpText.needHelp");
  const emailText = t("common:helpText.emailText");
  const testIdContactPhoneNumber = testId
    ? `${testId}-contact-phone-number`
    : "contact-phone-number";
  const testIdHelpService = testId ? `${testId}-help-service` : "help-service";

  const contactPhoneNumber = getManagerContactPhoneNumber(userCountry);

  const formattedPhoneNumber = getFormatPhoneNumber(String(contactPhoneNumber), userCountry);

  return (
    <div className={st["signin-form-info"]}>
      <Text
        testId={testId}
        box="full"
        size="14"
        color="gray-900"
        font="system"
        alignmentHorizontal="center"
      >
        <>
          <p className={st["signin-form-info-text"]}>
            {needHelpText}
            &#8194;
            <Link href={`tel:${formattedPhoneNumber}`} testId={testIdContactPhoneNumber}>
              {formattedPhoneNumber}
            </Link>
          </p>
          <p className={st["signin-form-info-text"]}>
            {emailText}
            &#8194;
            <Link
              testId={testIdHelpService}
              href={composeGmailLink(emailsBook.helpService)}
              blank
              asNextLink={false}
            >
              {emailsBook.helpService}
            </Link>
          </p>
        </>
      </Text>
    </div>
  );
};
