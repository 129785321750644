// Core
import { useCallback } from "react";
import { useRouter } from "next/router";

// Definitions
import type { SigninType } from "bus/auth/models";
import { HttpStatusCode } from "models/Http";

// Hooks
import { useLeadSignupVerifiedCreateMutation } from "client/core/lead-signup/hooks/mutations/use-lead-signup-verified-create-mutation";

// Utils
import { notificationService } from "utils/notifications";
import { getHttpErrorMessageWithTranslations } from "utils/notifications-message";
import { book } from "init/book";

type UseCallbackCreateVerifiedLeadSignupType = (data: SigninType) => void;

type UseLeadSignupVerifiedCreateType = {
  loadingVerifiedLeadSignupCreate: boolean;
  onCreateLeadSignupVerified: UseCallbackCreateVerifiedLeadSignupType;
};

export const useLeadSignupVerifiedCreate = (): UseLeadSignupVerifiedCreateType => {
  const router = useRouter();
  const { loadingVerifiedLeadSignupCreate, onCreateLeadSignupVerified } =
    useLeadSignupVerifiedCreateMutation();

  const onCreate = useCallback<UseCallbackCreateVerifiedLeadSignupType>((data) => {
    void (async () => {
      try {
        const [createLeadVerifiedError, createLeadVerifiedResult] =
          await onCreateLeadSignupVerified(data);

        if (createLeadVerifiedError) {
          const isError400 = createLeadVerifiedError.status === HttpStatusCode.badRequest;
          const isError500 = createLeadVerifiedError.status >= HttpStatusCode.server;

          if (isError400 || isError500) {
            notificationService.showError(getHttpErrorMessageWithTranslations());
          }

          throw new Error("Something went wrong in lead verified create flow");
        }

        await router.push({
          pathname: book.signupProfile,
          query: { hash: createLeadVerifiedResult?.hash },
        });
      } catch (error) {
        console.warn(error);
        notificationService.showError(getHttpErrorMessageWithTranslations());
      }
    })();
  }, []);

  return {
    loadingVerifiedLeadSignupCreate,
    onCreateLeadSignupVerified: onCreate,
  };
};
