// Core
import { ReactNode } from "react";
import type { FieldErrors, FieldValues, FormState } from "react-hook-form";

// Definitions
import type { BaseUnknownRecordType, BaseShapeFormType } from "models/Base";

export type FieldMetaStatus = "error" | "success" | undefined;
export type FieldMetaHelpText = { help?: ReactNode };
export type TouchedFields<T extends FieldValues = BaseShapeFormType> =
  | FormState<T>["touchedFields"]
  | undefined;
export type ErrorsFields = FieldErrors<BaseShapeFormType> | undefined;

export type FieldMetaType = {
  name: string;
  status: FieldMetaStatus;
  helpText: FieldMetaHelpText;
};

export type FieldsMetaType<Shape extends object = object> = {
  [key in keyof Shape]: FieldMetaType;
};

type SetResetFnType = (values?: BaseUnknownRecordType, options?: Record<string, boolean>) => void;
export type SetErrorFnType<T> = (name: keyof T, error: { type: string; message: string }) => void;

export type FormActions<T> = {
  setError?: SetErrorFnType<T>;
  reset?: SetResetFnType;
  callback?: (args?: Partial<T>) => void;
  onEdit?: (value: boolean) => void;
  onAct?: () => void;
};

export type FormSubmitParams<T, G = T> = {
  values: G;
  acts?: FormActions<T>;
  meta?: {
    emitFormError422?: boolean;
    showNotificationError422?: boolean;
  } | null;
};
export type FormSubmitFn<T, G = T, R = void> = (values: FormSubmitParams<T, G>) => R;

export type FormSubmitCbFn<T, G = T> = (values: FormSubmitParams<T, G>) => void;

export type FormStepRefType = {
  onFinish?: () => void;
};

// forms
export enum FormSigninEnum {
  login = "login",
  password = "password",
}

export enum QuantityEnum {
  value = "value",
  step = "step",
  default = "default",
}

export type StringEditType = {
  title: string;
};
